<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-card-title class="text-no-wrap">
            Recouvrement
          </v-card-title>
          <v-card-subtitle>Détails sur le recouvrement</v-card-subtitle>
        </div>

        <v-avatar
          color="primary"
          size="48"
          class="v-avatar-light-bg primary--text me-5"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiCashMultiple }}
          </v-icon>
        </v-avatar>
      </div>

      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiImageText }}
              </v-icon>
              Libellé:
            </span>
            <span class="text--secondary font-weight-bold">
              {{ $store.state.recovery.recovery.rec_type ? $store.state.recovery.recovery.rec_type.label : null }}
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiAccount }}
              </v-icon>
              Membre:
            </span>
            <span
              class="text--secondary font-weight-bold"
              @click="openUserDetails($store.state.recovery.recovery.user)"
            >
              <v-chip pill>
                <v-avatar
                  :color=" $store.state.recovery.recovery.user.profile_picture_path ? '' : 'primary'"
                  :class=" $store.state.recovery.recovery.user.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
                  size="32"
                  left
                >
                  <v-img
                    v-if=" $store.state.recovery.recovery.user.profile_picture_path"
                    :src="`${ $store.state.recovery.recovery.user.profile_picture_path}`"
                  ></v-img>
                  <span v-else>{{ $store.state.recovery.recovery.user.name !== null && $store.state.recovery.recovery.user.name !== undefined ? $store.state.recovery.recovery.user.name.slice(0,2).toUpperCase() : null }}</span>
                </v-avatar>
                {{ $store.state.recovery.recovery.user.civility }} {{ $store.state.recovery.recovery.user.name }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCash }}
              </v-icon>
              Montant:
            </span>
            <span class="text--secondary font-weight-bold">
              <v-chip
                color="primary"
                small
              >
                {{ formatAmount($store.state.recovery.recovery.amount) }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCash }}
              </v-icon>
              Frais :
            </span>
            <span class="text--secondary font-weight-bold">
              <v-chip
                color="secondary"
                small
              >
                {{ formatAmount($store.state.recovery.recovery.fee_amount ? $store.state.recovery.recovery.fee_amount : 0) }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            v-show="$store.state.recovery.recovery.admin !== null"
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiAccount }}
              </v-icon>
              Validé par:
            </span>
            <span
              class="text--secondary font-weight-bold"
              @click="openUserDetails($store.state.recovery.recovery.admin)"
            >
              <v-chip pill>
                <v-avatar
                  :color="$store.state.recovery.recovery.admin && $store.state.recovery.recovery.admin.profile_picture_path ? '' : 'primary'"
                  :class="$store.state.recovery.recovery.admin && $store.state.recovery.recovery.admin.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
                  size="32"
                  left
                >
                  <v-img
                    v-if="$store.state.recovery.recovery.admin && $store.state.recovery.recovery.admin.profile_picture_path"
                    :src="`${ $store.state.recovery.recovery.admin.profile_picture_path}`"
                  ></v-img>
                  <span v-else>{{ $store.state.recovery.recovery.admin && $store.state.recovery.recovery.admin.name !== undefined ? $store.state.recovery.recovery.admin.name.slice(0,2).toUpperCase() : null }}</span>
                </v-avatar>
                {{ $store.state.recovery.recovery.admin ? $store.state.recovery.recovery.admin.civility : '' }} {{ $store.state.recovery.recovery.admin ? $store.state.recovery.recovery.admin.name : '' }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendar }}
              </v-icon>
              Date de création:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.recovery.recovery.created_at ? formattingDate($store.state.recovery.recovery.created_at) : null }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendarEdit }}
              </v-icon>
              Date de mise à jour:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.recovery.recovery.updated_at ? formattingDate($store.state.recovery.recovery.updated_at) : null }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCalendarMinus }}
              </v-icon>
              Date de suppression:
            </span>
            <span class="text--secondary font-weight-bold">{{ $store.state.recovery.recovery.deleted_at ? formattingDate($store.state.recovery.recovery.deleted_at) : null }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-tooltip
          bottom
          color="primary"
        >
          <template #activator="{ attr, on }">
            <v-btn
              v-bind="attr"
              elevation="6"
              icon
              x-large
              class="primary"
              v-on="on"
              @click="openDialog"
            >
              <v-icon
                dark
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </v-btn>
          </template>
          <span>Valider</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <validate-recovery-form
      :dialog="dialog"
      :object="item"
      @clickout="closeDialog"
      @submitted="loading"
    />
  </div>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api'
import { formatDate } from '@core/date/dateFunctions'
import {
  mdiAccount,
  mdiBadgeAccountAlert,
  mdiBusArticulatedEnd,
  mdiBusArticulatedFront, mdiCalendar, mdiCalendarEdit, mdiCalendarMinus,
  mdiCash, mdiCashMarker, mdiCashMultiple, mdiCheck,
  mdiClockStart,
  mdiImageText, mdiMapMarkerDown, mdiMapMarkerUp,
  mdiRoad, mdiSeatPassenger,
} from '@mdi/js'
import ValidateRecoveryForm from '@core/components/recovery/ValidateRecoveryForm'
import { intToCurrency } from '@core/dynamicComponents/dynamicComponents'
import router from '@/router'
import store from '@/store'

export default {
  name: 'RecoveryCard',
  components: { ValidateRecoveryForm },
  setup() {
    const route = getCurrentInstance().proxy.$route
    const item = ref({})
    const formattingDate = date => formatDate(date)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const closeDialog = () => {
      dialog.value = false
    }

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const openDialog = () => {
      item.value = {
        recovery_id: store.state.recovery.recovery.id,
        operator_id: null,
      }
      dialog.value = true
    }

    const openUserDetails = elt => {
      router.push({ name: 'showuser', params: { id: elt.id } })
    }

    return {
      formatAmount,
      openDialog,
      openUserDetails,
      formattingDate,
      dialog,
      item,
      isDialogVisible,
      loading,
      closeDialog,
      icons: {
        mdiRoad,
        mdiImageText,
        mdiCash,
        mdiClockStart,
        mdiBusArticulatedFront,
        mdiBusArticulatedEnd,
        mdiSeatPassenger,
        mdiCashMarker,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiMapMarkerDown,
        mdiMapMarkerUp,
        mdiBadgeAccountAlert,
        mdiCashMultiple,
        mdiCheck,
        mdiAccount,
      },
    }
  },
}
</script>

<style scoped>

</style>
