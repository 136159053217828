<template>
  <v-card>
    <div class="d-flex align-center justify-space-between">
      <div>
        <v-card-title class="text-no-wrap">
          Statut du recouvrement
        </v-card-title>
        <v-card-subtitle>Historique du statut</v-card-subtitle>
      </div>

      <v-avatar
        color="primary"
        size="48"
        class="v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          color="primary"
        >
          {{ icons.mdiClockStart }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text>
      <v-timeline
        dense
        class="timeline-custom-dense timeline-custom-dots"
      >
        <!-- Timeline Item: Flight -->
        <v-timeline-item
          v-for="statusRecovery in $store.getters['statusRecovery/statuses_recovery']"
          :key="statusRecovery.id"
          small
          color="transparent"
        >
          <template #icon>
            <v-avatar
              :color="statusRecovery.status_rec.color"
              class="v-avatar-light-bg primary--text"
              size="40"
            >
              <v-icon
                size="24"
                :color="statusRecovery.status_rec.color"
              >
                {{ statusRecovery.is_active === true ? icons.mdiCheck : icons.mdiMinusCircleOutline }}
              </v-icon>
            </v-avatar>
          </template>
          <!-- Header -->
          <div class="d-flex justify-space-between">
            <p class="mb-1 text--primary font-weight-semibold">
              <v-chip
                small
                label
                :color="statusRecovery.status_rec.color"
              >
                {{ statusRecovery.status_rec.label }}
              </v-chip>
            </p>
          </div>

          <!-- Content -->

          <p>{{ formattingDate(statusRecovery.created_at) }}</p>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiArrowRight,
  mdiCashCheck, mdiClockStart,
  mdiFilePdf,
  mdiMessageTextOutline,
  mdiMinusCircleOutline,
  mdiPhone, mdiCheck,
} from '@mdi/js'
import { getCurrentInstance, ref, onMounted } from '@vue/composition-api'
import { formatDate } from '@core/date/dateFunctions'
import store from '@/store'
import router from '@/router'

export default {
  name: 'RecoveryStatusList',

  setup() {
    const isDialogVisible = ref(false)
    const route = getCurrentInstance().proxy.$route

    const getStatusList = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        store.state.statusRecovery.field.recovery_id = id
        isDialogVisible.value = true
        store.dispatch('statusRecovery/getStatusesRecList', { field: store.state.statusRecovery.field }).then(() => {
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
          router.back()
        })
      }
    }

    const formattingDate = date => formatDate(date)

    onMounted(getStatusList)

    return {
      formattingDate,
      icons: {
        mdiFilePdf,
        mdiArrowRight,
        mdiMessageTextOutline,
        mdiPhone,
        mdiCashCheck,
        mdiMinusCircleOutline,
        mdiClockStart,
        mdiCheck,
      },
    }
  },
}
</script>

<style scoped>

</style>
