<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">Valider le recouvrement</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="$store.state.recovery.recovery.amount"
                    label="Montant (FCFA)"
                    type="text"
                    readonly
                    :rules="[rules.recovery_id]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="$store.state.recovery.recovery.svc_number"
                    label="Compte bénéficiaire"
                    type="text"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-img
                    :src="$store.state.recovery.recovery.operator.logo_path"
                    class="bg-grey-lighten-2"
                    max-height="125"
                    contain
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              VALIDER
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, ref, watch,
  onMounted, onBeforeMount, onUpdated, onActivated, onBeforeUpdate,
} from '@vue/composition-api'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'ValidateRecoveryForm',

  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    object: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const color = ref(null)
    const picker = ref(null)
    const menuref = ref(null)
    const operators = ref([])
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const rules = {
      recovery_id: true,
      operator_id: true,
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      message.value = ''
      Object.keys(rules).forEach(key => {
        rules[key] = true
      })
      instance.emit('clickout')
    }

    /* watch(props.dialog, dialog => {
      // eslint-disable-next-line no-unused-expressions
      dialog || close()
    }) */

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      item.value.operator_id = store.state.recovery.recovery.operator_id
      store.dispatch('recovery/validateRecoveryToPay', item.value).then(response => {
        isDialogVisible.value = false
        notify(true, true, response.message)
        close()
      }).catch(error => {
        form.value.validate()
        console.log(error)
        message.value = error.response ? error.response.data.message : ''
        errors.value = error.response ? error.response.data.errors : ''
        if (error.response && error.response.data.errors !== undefined) {
          rules.recovery_id = error.response.data.errors.recovery_id !== undefined ? error.response.data.errors.recovery_id[0] : true
          rules.operator_id = error.response.data.errors.operator_id !== undefined ? error.response.data.errors.operator_id[0] : true
        }
        isDialogVisible.value = false
        isAlertVisible.value = true
      })
    }

    const getOperatorList = (page = 1) => {
      store.dispatch('operator/getOperatorsList', { page, field: store.state.operator.field }).then(() => {
      }).catch(err => {
        console.log(err)
      })
    }

    onUpdated(() => {
      getOperatorList()
    })

    onBeforeUpdate(() => {
      operators.value = []
      store.getters['operator/operators'].forEach(elt => {
        operators.value.push({ text: elt.label, value: elt.id, image: elt.logo_path })
      })
    })

    return {
      close,
      operators,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      item,
      color,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
