<template>
  <div>
    <recovery-card class="mb-4" />
    <recovery-status-list />
  </div>
</template>

<script>
import RecoveryCard from '@core/components/recovery/RecoveryCard'
import RecoveryStatusList from '@core/components/recovery/RecoveryStatusList'

export default {
  name: 'RecoveryPanel',
  components: { RecoveryStatusList, RecoveryCard },
}
</script>

<style scoped>

</style>
