<template>
  <div>
    <recovery-payment-card class="mb-4" />
    <payment-status
      v-show="$store.state.recovery.recovery.payment_id"
      :payment_id="$store.state.recovery.recovery.payment_id"
    />
  </div>
</template>

<script>
import RecoveryPaymentCard from '@core/components/recovery/RecoveryPaymentCard'
import PaymentStatus from '@core/components/payment/PaymentStatus'

export default {
  name: 'RecoveryPayment',
  components: { PaymentStatus, RecoveryPaymentCard },
}
</script>

<style scoped>

</style>
